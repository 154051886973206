import React, { useState } from 'react';
import { Box, Typography, Button, SwipeableDrawer } from '@mui/material';
import servicesData from './services.json';

const ServicePage = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  // Открыть выезжающее окно с выбранной услугой
  const handleServiceClick = (service) => {
    setSelectedService(service);
    setDrawerOpen(true);
  };

  // Закрыть выезжающее окно
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedService(null);
  };

  return (
    <Box>
      {/* Информационный блок */}
      <Box component="section" sx={{ p: 2, mt: 1 }}>
        <Box
          sx={{
            p: 2,
            pt: 3,
            pb: 3,
            borderRadius: '15px',
            border: '1px solid #ccc',
            width: '100%',
            margin: '0 auto',
            position: 'relative',
            background: '#ffffff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.15rem', pb: 1 }}>
            🎉 Услуги и возможности
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '0.9rem',
              lineHeight: '1.2',
              letterSpacing: '0.008em',
            }}
          >
            Оставляйте заявку — живите спокойно. Мы заплатим айдато, налоги, поможем с переездом, ремонтом,
            подбором мебели, найдем и привезем необходимую технику, оформим ВНЖ, подготовим квартиру к аренде.
          </Typography>
        </Box>
      </Box>

      {/* Блок услуг */}
      <Box component="section" sx={{ p: 2 }}>
        <Typography
          component="div"
          variant="h5"
          sx={{ fontSize: '1rem', pl: 1, mb: 1, color: '#5b5b5b', fontWeight: '600' }}
        >
          Наши услуги
        </Typography>
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            gap: 2,
            pl: 1,
            pr: 1,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              flexWrap: 'wrap',
              gap: 1.5,
            }}
          >
            {servicesData.map((service, index) => (
              <Box
                width="100%"
                key={index}
                onClick={() => handleServiceClick(service)}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  p: 1,
                  borderRadius: '15px',
                  border: '2px solid #ffdfc8',
                  backgroundColor: '#fff8f3',
                  transition: 'all 0.3s ease',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    backgroundColor: '#ffe6d5',
                  },
                }}
              >
                <img
                  src={service.icon}
                  alt={`${service.name} icon`}
                  style={{ width: '30px', height: '30px', marginRight: '15px' }}
                />
                <Typography variant="body1" sx={{ fontWeight: '500', color: '#3c3c3c' }}>
                  {service.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Выезжающее окно с описанием услуги */}
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        onOpen={() => {}}
        sx={{
          width: '100%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '100%',
            height: '100vh',
            padding: 2,
            borderRadius: '15px 15px 0 0',
            overflowY: 'auto',
          },
        }}
      >
        {selectedService && (
          <Box sx={{ height: '100%', pt: '90px' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, fontSize: '1.3rem' }}>
              {selectedService.name}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {selectedService.description}
            </Typography>
            <Button variant="contained" color="primary" fullWidth onClick={handleCloseDrawer}>
              Отмена
            </Button>
          </Box>
        )}
      </SwipeableDrawer>
    </Box>
  );
};

export default ServicePage;
