import React, { useState } from 'react';
import { Box, Typography, Avatar, CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import wp from '../../assets/wp.svg';
import tg from '../../assets/tg.svg';
import vb from '../../assets/vb.svg';
import call from '../../assets/call.svg';
import sms from '../../assets/sms.svg';
import mng from '../../assets/mng.png';

const ContactInfo = ({ icon, text }) => (
  <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary', display: 'flex' }}>
    <img src={icon} alt="Logo" style={{ width: '16px', height: 'auto' }} />
    <Box sx={{ pl: 1 }}>{text}</Box>
  </Typography>
);

const ProfilePage = () => {
  const userId = useSelector(state => state.userId);

  // Адрес для отображения
  const address = " Cumhuriyet Mah. Sanat Okulu Cd No:1 Alanya Merkez/Antalya, Türkiye";

  // Координаты для карты (широта и долгота)
  const latitude = 36.5398243; // Пример широты
  const longitude = 32.0268625; // Пример долготы

  // Состояние для отслеживания загрузки карты
  const [isMapLoading, setMapLoading] = useState(true);

  return (
    <Box>
      <Box component="section" sx={{ p: 2, mt: 1 }}>
        {/* Блок инфо */}
        <Box
          sx={{
            p: 2,
            pt: 3,
            pb: 3,
            borderRadius: '15px',
            border: '1px solid #ccc',
            width: '100%',
            margin: '0 auto',
            position: 'relative',
            background: '#ffffff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Avatar
              src={`https://rpclub.z-code.info/RPClub/files/${userId}.jpg`}
              alt="Profile Picture"
              sx={{ width: 100, height: 100, mr: 2 }}
            />
            <Box>
              <Typography variant="h6" sx={{ fontSize: '1.15rem', fontWeight: 'bold' }}>
                Василий Иванов
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '0.8rem', lineHeight: '1', color: "#adadad" }}>
                {userId}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box component="section" sx={{ p: 2 }}>
        <Typography component="div" variant="h5" sx={{ fontSize: '1rem', pl: 1, mb: 0, color: '#5b5b5b', fontWeight: '600' }}>
          Мой адрес
        </Typography>
      </Box>
      <Box component="section" sx={{ p: 2, mt: 0, pt: 0 }}>
        {/* Карточка с адресом и картой */}
        <Box
          sx={{
            p: 2,
            pt: 3,
            pb: 3,
            borderRadius: '15px',
            border: '1px solid #ccc',
            width: '100%',
            margin: '0 auto',
            position: 'relative',
            background: '#ffffff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            {/* Адрес */}
            <Typography
              variant="body1"
              sx={{ fontSize: '0.9rem', lineHeight: '1.2', letterSpacing: '0.008em', mb: 2 }}
            >
              {address}
            </Typography>
            {/* Google Map */}
            <Box
              sx={{
                width: '100%',
                height: '150px',
                borderRadius: '10px',
                border: '1px solid #ccc',
                overflow: 'hidden',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                position: 'relative',
              }}
            >
              {/* Лоадер */}
              {isMapLoading && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 1,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              {/* iframe */}
              <iframe
                title="Google Map"
                src={`https://www.google.com/maps?q=${latitude},${longitude}&hl=ru&z=14&output=embed`}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="eager"
                onLoad={() => setMapLoading(false)} // Скрываем лоадер после загрузки
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box component="section" sx={{ p: 2 }}>
        <Typography component="div" variant="h5" sx={{ fontSize: '1rem', pl: 1, mb: 0, color: '#5b5b5b', fontWeight: '600' }}>
          Персональный менеджер
        </Typography>
      </Box>
      <Box component="section" sx={{ p: 2, mt: 0, pt: 0 }}>
          <Card sx={{             display: 'flex',
            borderRadius: '15px',
            border: '1px solid #ccc',
            width: '100%',
            margin: '0 auto',
            position: 'relative',
            background: '#ffffff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', }}>
            <CardMedia
              component="img"
              sx={{ width: 120 }}
              image={mng}
              alt="Артём Чуйко"
            />
            <Box sx={{ flex: '1 0 auto', pb: 0, pt: 2, pl: 2, pr: 2 }}>
              <Typography component="div" variant="h5" sx={{ fontSize: '1.3rem' }}>
                Артём Чуйко
              </Typography>
              <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary' }}>
                Эксперт по недвижимости
              </Typography>
              <ContactInfo icon={call} text="+90 533 158 29 64"/>
              <ContactInfo icon={sms} text="artyom@restproperty.ru" />
              <Box sx={{ display: 'flex', alignItems: 'center', pt: 1, justifyContent: 'center' }}>
                <IconButton aria-label="whatsapp">
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    <img src={wp} alt="whatsapp" width="32" height="32" />
                  </a>
                </IconButton>
                <IconButton aria-label="telegram">
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    <img src={tg} alt="telegram" width="32" height="32" />
                  </a>
                </IconButton>
                <IconButton aria-label="viber">
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    <img src={vb} alt="viber" width="32" height="32" />
                  </a>
                </IconButton>
              </Box>
            </Box>
          </Card>
      </Box>
    </Box>
  );
};

export default ProfilePage;
