import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, TextField, CircularProgress } from '@mui/material';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import axios from 'axios';

const PromoPage = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [user, setUser] = useState(null);
  const [isTyping, setIsTyping] = useState(false); // Состояние для анимации печатания

  // Реф для контейнера сообщений
  const messagesEndRef = useRef(null);

  // Инициализация Telegram WebApp
  useEffect(() => {
    const initData = window.Telegram.WebApp.initDataUnsafe;
    setUser(initData?.user);
  }, []);

  // Прокрутка вниз после каждого обновления сообщений
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Логика добавления приветственного сообщения
  useEffect(() => {
    if (user) {
      setMessages((prevMessages) => [
        { sender: 'bot', text: `Здравствуйте, ${user.first_name} ${user.last_name}!` },
        ...prevMessages,
      ]);
    }
  }, [user]);

  // Отправка сообщения
  const handleSend = async () => {
    if (!input.trim()) return;

    // Добавление сообщения пользователя
    setMessages((prev) => [...prev, { sender: 'user', text: input }]);

    // Включаем анимацию "печатает..."
    setIsTyping(true);

    try {
      // Отправка запроса на Make.com
      const response = await axios.post('https://hook.eu2.make.com/cr5n83c1vtkzcxizg7475uj2g5hhgv5c', {
        user_id: user?.id || 'unknown',
        message: input,
      });

      // Останавливаем анимацию "печатает..."
      setIsTyping(false);

      // Убираем все, что внутри 【】 и сами скобки
      const cleanedText = response.data.replace(/【[^】]*】/g, '').trim();

      // Добавление очищенного ответа от Make.com
      setMessages((prev) => [
        ...prev,
        { sender: 'bot', text: cleanedText }
      ]);
    } catch (error) {
      console.error('Ошибка при отправке:', error);
      setIsTyping(false);
      setMessages((prev) => [...prev, { sender: 'bot', text: 'Ошибка. Попробуйте снова.' }]);
    }

    setInput('');
  };

  // Обработка нажатия Enter
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Отменяем стандартное поведение (переход на новую строку)
      handleSend();
    }
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      height: "calc(100vh - 226px)",
      justifyContent: "flex-start"
    }}>
      {/* Информационный блок */}
      <Box component="section" sx={{ p: 2, mt: 1 }}>
        <Box
          sx={{
            p: 2,
            pt: 3,
            pb: 3,
            borderRadius: '15px',
            border: '1px solid #ccc',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            width: '100%',
            margin: '0 auto',
            position: 'relative',
            background: '#ffffff',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.15rem', pb: 1 }}>
            👩🏼‍💻 Онлайн справочник AI
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '0.9rem',
              lineHeight: '1.2',
              letterSpacing: '0.008em',
            }}
          >
             Я помогу вам разобраться в законодательных аспектах, а также поделюсь полезными советами по оформлению ВНЖ, налоговым вопросам и особенностям жизни в Турции.
          </Typography>
        </Box>
      </Box>

      <Box component="section" sx={{ height: '100%', p: 2, pt: 0 }}>

        <Box
          sx={{
            borderRadius: '15px',
            border: '1px solid #ccc',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            padding: 2,
            height: '100%',
            overflowY: 'auto',
            marginBottom: 2,
            backgroundColor: '#ffffff'
          }}
        >
          {messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                margin: '10px 0',
                textAlign: msg.sender === 'user' ? 'right' : 'left',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  display: 'inline-block',
                  padding: 2,
                  borderRadius: '15px',
                  padding: '8px 15px',
                  lineHeight: '1.3',
                  backgroundColor: msg.sender === 'user' ? '#d1f5d3' : '#f1f1f1',
                }}
              >
                {msg.text}
              </Typography>
            </Box>
          ))}

          {/* Анимация "печатает..." */}
          {isTyping && (
            <Box sx={{ display: 'inline-block', padding: 2, borderRadius: '10px', backgroundColor: '#f1f1f1' }}>
              <Typography variant="body2">Печатает</Typography>
              <CircularProgress size={24} sx={{ marginLeft: 1 }} />
            </Box>
          )}

          {/* Ссылка для прокрутки вниз */}
          <div ref={messagesEndRef} />
        </Box>

        <Box sx={{
          display: 'flex',
          alignItems: 'stretch',
          flexDirection: 'row',
        }}>
          <TextField
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
            fullWidth
            variant="outlined"
            placeholder="Введите сообщение..."
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderRadius: '15px',         // Скругление углов
                  border: '1px solid #ccc',     // Рамка вокруг поля
                },
                '&:hover fieldset': {
                  borderColor: '#007bff',       // Цвет рамки при наведении
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#007bff',       // Цвет рамки при фокусе
                },
                // Отступы для поля ввода
                '& .MuiOutlinedInput-input': {
                  padding: '10px 15px',          // Задаем отступы для самого инпута
                }
              },
            }}
          />

          <Button
            onClick={handleSend}
            variant="contained"
            sx={{ backgroundColor: '#ec6608', color: '#fff', width: '50px', ml: 1, borderRadius: '15px', minWidth: 'unset', p: 0 }}
          >
            <SendOutlinedIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PromoPage;
