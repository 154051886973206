// store.js
import { configureStore } from '@reduxjs/toolkit';

const initialState = {
    userId: null,
    userName: null,
    userPlatform: null
  };
  
  const rootReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_USER_DATA':
        return {
          ...state,
          userId: action.payload.userId,
          userName: action.payload.userName,
          userPlatform: action.payload.userPlatform
        };
      default:
        return state;
    }
  };

// Создаем хранилище с помощью configureStore
const store = configureStore({
  reducer: rootReducer,
  // Дополнительные параметры конфигурации, если необходимо
});

export default store;
