import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { DirectionsCarFilledOutlined, AccountBalanceWalletOutlined, LocalLaundryServiceOutlined, EmojiEventsOutlined } from '@mui/icons-material';
import './HomePage.css';
import add from '../../assets/add.svg';
import help from '../../assets/help.svg';
import rent from '../../assets/rent.svg';
import ikamet from '../../assets/ikamet.svg';
import clean from '../../assets/clean.svg';
import bill from '../../assets/bill.svg';

const services = [
  { name: 'Трансфер', ico: rent, link: 'https://restproperty.ru/dubai/' },
  { name: 'Икамет', ico: ikamet, link: 'https://restproperty.ru/thailand/' },
  { name: 'Клининг', ico: clean, link: 'https://restproperty.ru/bali/' },
  { name: 'Комуналка', ico: bill, link: 'https://restproperty.ru/kipr/' },
  { name: 'Помощ', ico: help, link: 'https://restproperty.ru/turkey/' },
];
const transactions = [
  { id: 1, icon: 'win', type: 'Приход', service: 'Бонус за отзыв', date: '12.11.2024', price: 5000 },
  { id: 2, icon: 'rent', type: 'Расход', service: 'Оплата услуг трансфера', date: '11.11.2024', price: -2000 },
  { id: 3, icon: 'wallet', type: 'Приход', service: 'Пополнение баланса', date: '10.11.2024', price: 3000 },
  { id: 4, icon: 'clean', type: 'Расход', service: 'Оплата клининга', date: '09.11.2024', price: -1500 },
];
const iconMap = {
  wallet: <AccountBalanceWalletOutlined />,
  rent: <DirectionsCarFilledOutlined />,
  clean: <LocalLaundryServiceOutlined />,
  win: <EmojiEventsOutlined />,
};

const ServiceItem = ({ name, ico, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        minWidth: '120px', // Фиксированная ширина для плиток
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 1,
        borderRadius: '15px',
        border: '2px solid #ffdfc8',
        backgroundColor: '#fff8f3',
        transition: 'all 0.3s ease',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          backgroundColor: '#ffe6d5',
        },
      }}
    >
      <Box display="flex" alignItems="center">
        <img
          src={ico}
          alt={`${name} icon`}
          style={{
            width: '40px',
            height: '40px',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Typography variant="body1" sx={{ color: '#000', fontWeight: '500', pt: 1 }}>
        {name}
      </Typography>
    </Box>
  </a>
);

const HomePage = () => {
  return (
    <Box>
      <Box component="section" sx={{ p: 2, mt: 1 }}>
        {/* Блок баланса */}
        <Box
          sx={{
            p: 2,
            pt: 3,
            pb: 3,
            borderRadius: '15px',
            border: '1px solid #ccc',
            width: '100%',
            margin: '0 auto',
            position: 'relative',
            background: 'linear-gradient(135deg, #e8f8ff, #76aaff)',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              🪙 Rest Coin
            </Typography>
            <Typography variant="body1">Diamant</Typography>
          </Box>
          <Typography variant="h4">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: '9px',
                alignItems: 'center',
                pt: 1,
                pb: 1,
              }}
            >
              <Box>
                <Box component="span" sx={{ fontWeight: 'normal' }}>
                  4500
                </Box>{' '}
                <Box component="span" sx={{ fontWeight: 'bold', color: '#ec6608', fontSize: '22px' }}>
                  RC
                </Box>
              </Box>
              <img src={add} alt="logo" style={{ width: '30px', height: '30px' }} />
            </Box>
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '9px', alignItems: 'center' }}>
            <Typography variant="body1">Василий Иванов</Typography>
            <Typography variant="body1">ID: 12345</Typography>
          </Box>
        </Box>
      </Box>
      {/* Блок услуг */}
      <Box component="section" display="flex" justifyContent="space-between" sx={{ pl: 2, pr: 2, mt: 2 }}>
        <Typography
          component="div"
          variant="h5"
          sx={{ fontSize: '1rem', pl: 1, mb: 1, color: '#5b5b5b', fontWeight: '600' }}
        >
          Популярные услуги
        </Typography>
        <Typography
          component={Link}
          to="/service"
          variant="h5"
          sx={{
            fontSize: '1rem',
            pr: 1,
            mb: 1,
            color: '#5b5b5b',
            textDecoration: 'none',
            '&:hover': { color: '#ec6608' },
          }}
        >
          Все &rarr;
        </Typography>
      </Box>
      <Box className="horizontal-scroll-container" sx={{ display: 'flex', overflowX: 'auto', gap: 2, p: 2, pt: 1, pb: 2, }}>
        {services.map((service, index) => (
          <ServiceItem key={index} {...service} />
        ))}
      </Box>
      {/* Блок транзакций */}
      <Box component="section" sx={{ pl: 2, pr: 2 }}>
        <Typography
          component="div"
          variant="h5"
          sx={{ fontSize: '1rem', pl: 1, mb: 1, mt: 2, color: '#5b5b5b', fontWeight: '600' }}
        >
          Движения по счету
        </Typography>
        <List>
          {transactions.map((transaction) => (
            <div key={transaction.id}>
              <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ListItemIcon>
                    {iconMap[transaction.icon] &&
                      React.cloneElement(iconMap[transaction.icon], {
                        sx: {
                          color: transaction.type === 'Приход' ? 'green' : 'red',
                        },
                      })}
                  </ListItemIcon>
                  <ListItemText
                    primary={transaction.service}
                    secondary={
                      <Typography component="span" variant="body2" color="textSecondary">
                        {transaction.date}
                      </Typography>
                    }
                  />
                </div>
                <Typography
                  variant="body2"
                  style={{
                    color: transaction.price > 0 ? 'green' : 'red',
                  }}
                >
                  {transaction.price > 0 ? `+${transaction.price} RC` : `${transaction.price} RC`}
                </Typography>
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default HomePage;
