import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MainreIcon from '../../assets/m1.png';
import ServiceIcon from '../../assets/reserve.png';
import PromoIcon from '../../assets/m2.png';
import ProfileIcon from '../../assets/m4.png';
import Paper from '@mui/material/Paper';
import './Menu.css';

const Menu = () => {
    const location = useLocation(); // Получение текущего маршрута
    const [value, setValue] = React.useState(0);

    // Карта маршрутов и индексов
    const routes = ['/', '/service', '/promotions', '/profile'];

    useEffect(() => {
        const currentIndex = routes.indexOf(location.pathname);
        if (currentIndex !== -1) {
            setValue(currentIndex); // Устанавливаем индекс активного маршрута
        }
    }, [location.pathname]); // Срабатывает при изменении маршрута
    return (
        <div>
            <Paper sx={{ position: 'fixed', bottom: 15, left: 0, right: 0, marginLeft: 2, marginRight: 2, zIndex: 5 }} elevation={10}>
                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => setValue(newValue)}
                    showLabels
                >
                    <BottomNavigationAction
                        label="Главная" 
                        icon={<img src={MainreIcon} alt="Главная" style={{ width: 25, height: 25 }}/>}
                        component={Link} 
                        to="/"
                    />
                    <BottomNavigationAction
                        label="Услуги" 
                        icon={<img src={ServiceIcon} alt="Услуги" style={{ width: 25, height: 25 }}/>} 
                        component={Link} 
                        to="/service" 
                    />
                    <BottomNavigationAction
                        label="Места" 
                        icon={<img src={PromoIcon} alt="Места" style={{ width: 25, height: 25 }}/>}
                        component={Link} 
                        to="/promotions"
                    />
                    <BottomNavigationAction
                        label="Профиль" 
                        icon={<img src={ProfileIcon} alt="Профиль" style={{ width: 25, height: 25 }}/>}
                        component={Link} 
                        to="/profile"
                    />
                </BottomNavigation>
            </Paper>
        </div>
    );
};

export default Menu;