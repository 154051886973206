import React from 'react';
import { useSelector } from 'react-redux';

const HeaderBlock = () => {
    // Получаем платформу из Redux
    const userPlatform = useSelector(state => state.userPlatform);
    // Определяем высоту блока в зависимости от платформы
    const blockHeight = (userPlatform === "android" || userPlatform === "ios") ? 76 : 10;

    return (
        <div style={{height: `${blockHeight}px`}} >
            {/* Внутренний контент, если нужен */}
        </div>
    );
};

export default HeaderBlock;
